// text classes
.text-gray {
  color: $color-secondary
}

.text-error {
  //text-transform: capitalize;
  color: $color-yellow
}

.text-center {
  text-align: center;
}

// flexbox classes

.flex {
  display: flex;
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.grow {
  flex-grow: 1;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.form-container {
  max-width: 600px;
  margin: 80px auto 0;
  flex-direction: column;
  text-align: center;
}