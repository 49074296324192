@import './src/assets/styles/variables';

[data-container='profile'] {
  display: flex;
  flex-wrap: wrap;
  .notification {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    padding: 15px 30px;
    cursor: pointer;
    &.payment-error {
      align-items: flex-start;
      justify-content: flex-start;
      svg {
        margin-right: 20px;
        margin-top: 5px;
        flex-shrink: 0;
      }
    }
    h4 {
      font-size: 18px;
      font-weight: normal;
    }
    p {
      font-size: 15px;
      color: $color-secondary;
    }
  }
}

[data-container='plan-info'] {
  display: flex;
  flex-direction: column;
  flex-basis: 345px;
  margin-right: 30px;
  text-align: left;
  margin-bottom: 25px;
  p {
    color: $color-yellow;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: bold;
  }
  h4 {
    color: white;
    font-size: 24px;
    margin: 0;
  }
  span {
    font-size: 13px;
    color: $color-secondary;
    margin-bottom: 25px;
  }
  aside {
    margin-left: 4px;
  }
  li {
    font-size: 15px;
    padding: 5px;
  }
  a {
    text-align: left;
    margin-top: 20px;
  }
}

[data-container='subscription-info'] {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  article {
    flex-grow: 1;
    position: relative;
    box-shadow: unset;
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      margin-right: 2px;
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        background-color: #232323;
        width: 2px;
        display: block;
      }
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  span {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 15px;
    display: inline-block;
  }
  h4 {
    margin-bottom: 10px;
    color: #fff;
  }
  p {
    font-size: 15px;
    color: $color-secondary;
    margin-bottom: 0;
  }
  .radius {
    border-radius: 0.25rem !important;
    &:after {
      all: unset !important;
    }
  }
  button {
    width: max-content;
    font-family: unset;
    letter-spacing: 1px;
    font-weight: 400;
    margin-top: 20px;
  }
}

[data-container='billing-info'] {
  span {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 3px;
    margin-bottom: 15px;
    display: inline-block;
  }
  p {
    font-size: 18px;
    margin-bottom: 15px;
  }

  button {
    text-align: left;
    letter-spacing: 1px;
    font-family: unset;
    font-weight: 400;
  }
}

@media (max-width: 769px) {
  [data-container='subscription-info'] {
    flex-direction: column;
    article {
      border-radius: 0.25rem !important;
      &:first-child {
        margin-right: 0;
        margin-bottom: 20px;
        &::after {
          all: unset;
        }
      }
    }
  }
}
