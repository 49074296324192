[data-container=reset-password] {
  h5 {
    margin-bottom: 25px;
  }
  li {
    display: flex;
    text-align: left;
    align-items: center;
    svg {margin-right: 5px}
  }
}

[data-container=forgot-password] {
  h5 {
    margin-bottom: 25px;
  }
}