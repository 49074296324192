@import "src/assets/styles/variables";

[data-container=plan-card] {
  background-color: #232323;
  padding: 25px 24px;
  min-height: 460px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h4 {
    font-size: 24px;
    margin-bottom: 5px;
    color: #fff;
  }
  p {
    font-size: 14px;
    color: $color-secondary;
    margin-bottom: 20px;
  }
  h3 {
    font-size: 32px;
    margin-top: 0;
    color: #fff;
    margin-bottom: 25px;
  }
  li {
    font-size: 15px;
    padding: 5px;
  }
  button {
    margin-top: 25px;
    text-transform: uppercase;
    padding-top: 20px;
    padding-bottom: 20px;
    &.active {
      color: $color-yellow;
      border: unset;
      background-color: #00000033;
      opacity: 1;
    }
  }
}