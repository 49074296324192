/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Futura PT Book';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT Book'), url('../fonts/FuturaCyrillicBook.woff') format('woff');
}


@font-face {
  font-family: 'Futura PT Light';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT Light'), url('../fonts/FuturaCyrillicLight.woff') format('woff');
}


@font-face {
  font-family: 'Futura PT Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT Medium'), url('../fonts/FuturaCyrillicMedium.woff') format('woff');
}


@font-face {
  font-family: 'Futura PT Demi';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT Demi'), url('../fonts/FuturaCyrillicDemi.woff') format('woff');
}


@font-face {
  font-family: 'Futura PT Heavy';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT Heavy'), url('../fonts/FuturaCyrillicHeavy.woff') format('woff');
}


@font-face {
  font-family: 'Futura PT Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT Bold'), url('../fonts/FuturaCyrillicBold.woff') format('woff');
}


@font-face {
  font-family: 'Futura PT Extra Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Futura PT Extra Bold'), url('../fonts/FuturaCyrillicExtraBold.woff') format('woff');
}