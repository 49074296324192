@import './src/assets/styles/variables';

[data-container='subscription-container'] {
  & > h4 {
    margin-bottom: 25px;
    color: #fff;
    font-weight: 400;
  }

  .grid {
    grid-template-columns: minmax(0, 400px) minmax(0, 400px);
    justify-content: center;
    grid-row-gap: 35px;

    @media (max-width: 769px) {
      grid-template-columns: 100%;
    }
  }
}