@import "./assets/styles/variables";

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  margin-bottom: 50px;
  img {
    width: 110px;
    height: 15px;
    cursor: pointer;
  }
  .email-mobile {
    display: none;
  }
  .email-web {
    color: $color-secondary;
    font-size: 18px;
    margin-bottom: 0;
    margin-right: 20px;
    cursor: pointer;
  }
  @media (max-width: 769px) {
    flex-direction: column;
    align-items: flex-start;
    .email-web {display: none}
    .email-mobile {
      display: block;
      margin: 15px 0;
      color: $color-secondary
    }
  }
}

// toastify styles override
.Toastify__toast-body {
  font-size: 16px;
}

.Toastify__close-button {
  width: unset
}