[data-container='login-container'] {
  h6 {
    color: #fff;
    font-weight: 400;
  }
  img {
    width: 120px;
  }

  a {
    color: var(--primary);
    text-align: right;
    display: block;
    margin-bottom: 30px;
  }

  div {
    position: relative;
    display: flex;
    svg {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 25px;
    }
  }
}