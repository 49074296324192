[data-container='payment-success'] {
  max-width: 400px;
  text-align: center;
  margin: auto;
  font-size: 15px;
  font-weight: normal;
  p {
    font-size: 15px;
    margin: 20px 0;
  }
}