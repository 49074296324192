[data-container='recurly-form'] {
  display: flex;
  flex-wrap: wrap;

  label {
    font-size: 12px;
    font-family: 'Futura PT Bold', sans-serif;
    letter-spacing: 3px;
    text-transform: uppercase;
    flex: 0 0 49%;

    &:nth-of-type(even) {
      margin-left: 15px;
    }

    &[for='month'],
    &[for='year'] {
      margin-top: 15px;
    }

    small {
      font-family: system-ui;
      font-size: 18px;
      text-transform: initial;
      letter-spacing: initial;
      display: inline-block;
      &:first-letter {
        text-transform: uppercase;
      }
    }

    input::placeholder {
      font-family: system-ui;
      font-size: 18px;
      letter-spacing: initial;
    }
  }
  > div:nth-of-type(1) {
    margin: 5px 0 20px;
  }

  input,
  select {
    font-family: system-ui;
    letter-spacing: initial;
    background-color: #343434 !important;
  }

  div {
    width: 100%;
  }

  button {
    margin-top: 30px;
  }

  @media (max-width: 900px) {
    label {
      flex: 0 0 100%;
      &:nth-of-type(even) {
        margin-left: 0;
      }
      &[for='cvv'] {
        margin-top: 15px;
      }
    }
  }
}

.empty {
  color: gray;
}

.recurly-element {
  color-scheme: light;
  background-color: #343434 !important;
  --border-color: var(--form-element-border-color);
  color: #fff;
  --box-shadow: none;
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  outline: none;
  box-shadow: var(--box-shadow);
  font-weight: var(--font-weight);
  transition: background-color var(--transition), border-color var(--transition), color var(--transition),
    box-shadow var(--transition);
  height: calc(1rem * var(--line-height) + var(--form-element-spacing-vertical) * 2 + var(--border-width) * 2);
  width: 100%;
  appearance: none;
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  margin-bottom: 10px;
  font-size: 1rem;
  line-height: var(--line-height);
  margin-top: calc(var(--spacing) * 0.25);
}

.paypal-pay {
  display: none;
}

.credit-card {
  display: flex;
  flex-wrap: wrap;
}
.radio-container {
  padding: 15px 50px 15px 10px;
  background-color: #343434;
  border-radius: 4px;
}
.radio-container input[type='radio'] {
  width: 16px;
  height: 16px;
  background-color: #ffffff !important;
}

.radio-container label {
  font-size: 14px;
  margin-left: 5px;
}
.active-bar {
  background-color: rgb(87, 87, 87);
}
