@import "./assets/styles/index";
@import '@picocss/pico/scss/pico';
// override pico styles after importing

[data-theme="dark"] {
  --color: #FFF;
  --primary: #F04739;
  --primary-hover: #f44336;
  --primary-focus: rgba(229, 57, 53, 0.25);
  --primary-inverse: #FFF;
  --background-color: $black;
  --h4-color: #FFF;
  --h5-color: #979797;
  --h6-color: #979797;
  --form-element-background-color: #232323;
  --form-element-border-color: '';
  --form-element-color: #FFF;
  --form-element-placeholder-color: #979797;
  --form-element-focus-color: unset;
  --form-element-active-border-color: unset;
  --form-element-invalid-border-color: unset;
  --form-element-invalid-active-border-color: unset;
  --form-element-invalid-focus-color: unset;
  --form-element-valid-border-color: unset;
  --form-element-valid-active-border-color: unset;
  --form-element-valid-focus-color: unset;

  h1, h2, h3, h4, h5, p, span {
    margin: 0
  }

  input[aria-invalid=true], input[aria-invalid=false], select[aria-invalid=true], select[aria-invalid=false] {
    background-image: unset
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1280px;
    padding: 0 60px
  }
}

@media (min-width: 768px) {
  dialog article {
    max-width: 850px;
  }
}

button {
  margin: 0;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 3px;
  font-weight: bold;
  font-family: 'Futura PT Medium', sans-serif;

  &.outline {
    border: unset;
    padding: 0;
    font-size: 15px;
  }

  &:not(.outline) {
    text-transform: uppercase;
  }

  &:is([aria-current], :hover, :active, :focus):not(.secondary , .outline) {
    background-color: $red-400;
  }
}

[role=button].outline {
  padding: 0;
  border: unset;
  font-size: 15px;
}

article {
  margin: 0;
  background-color: #232323;
  padding: 25px 30px;

  header {
    background-color: unset;
  }
}

[aria-busy='true']:not(input, select, textarea)::before {
  width: 2em;
  height: 2em;
  border-radius: 2em;
}
